import { render, staticRenderFns } from "./BillingAccountKnowledge.vue?vue&type=template&id=4a3e9e60&scoped=true&"
import script from "./BillingAccountKnowledge.vue?vue&type=script&lang=ts&"
export * from "./BillingAccountKnowledge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3e9e60",
  null
  
)

export default component.exports