import { render, staticRenderFns } from "./add-phone-knowledge.vue?vue&type=template&id=04dc6329&scoped=true&"
import script from "./add-phone-knowledge.vue?vue&type=script&lang=ts&"
export * from "./add-phone-knowledge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04dc6329",
  null
  
)

export default component.exports