import { render, staticRenderFns } from "./CompleteCertification.vue?vue&type=template&id=6c7e7278&scoped=true&"
import script from "./CompleteCertification.vue?vue&type=script&lang=ts&"
export * from "./CompleteCertification.vue?vue&type=script&lang=ts&"
import style0 from "./CompleteCertification.vue?vue&type=style&index=0&id=6c7e7278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7e7278",
  null
  
)

export default component.exports