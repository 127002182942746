import { render, staticRenderFns } from "./set-deposit-knowledge.vue?vue&type=template&id=75d23b42&scoped=true&"
import script from "./set-deposit-knowledge.vue?vue&type=script&lang=ts&"
export * from "./set-deposit-knowledge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d23b42",
  null
  
)

export default component.exports