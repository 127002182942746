import { render, staticRenderFns } from "./DepositForm.vue?vue&type=template&id=03f33729&scoped=true&"
import script from "./DepositForm.vue?vue&type=script&lang=ts&"
export * from "./DepositForm.vue?vue&type=script&lang=ts&"
import style0 from "./DepositForm.vue?vue&type=style&index=0&id=03f33729&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f33729",
  null
  
)

export default component.exports