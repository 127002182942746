import { render, staticRenderFns } from "./seller-message-knowledge.vue?vue&type=template&id=394a6806&scoped=true&"
import script from "./seller-message-knowledge.vue?vue&type=script&lang=ts&"
export * from "./seller-message-knowledge.vue?vue&type=script&lang=ts&"
import style0 from "./seller-message-knowledge.vue?vue&type=style&index=0&id=394a6806&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394a6806",
  null
  
)

export default component.exports